import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from 'components/header'
import Footer from 'components/footer'

import HomePage from 'pages/HomePage'
import TeamPage from 'pages/Team'
import NewsPage from 'pages/News'
import PostPage from 'pages/PostPage'
import HiringPage from 'pages/HiringPage'
import ContactPage from 'pages/ContactPage'
import Kompete from 'pages/Kompete'
import Terms from 'pages/Terms'
import Privacy from 'pages/Privacy'
import CopyRight from './pages/CopyRight'

const withHeaderFooter = (Component) => (props) => {
	return (
		<div>
			<Header />
			<Component />
			<Footer />
		</div>
	)
}
const Routes = (props) => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={withHeaderFooter(HomePage)} />
				<Route exact path="/team" component={withHeaderFooter(TeamPage)} />
				<Route exact path="/news" component={withHeaderFooter(NewsPage)} />
				<Route exact path="/post/:id" component={withHeaderFooter(PostPage)} />
				<Route exact path="/hiring" component={withHeaderFooter(HiringPage)} />
				<Route exact path="/contact" component={withHeaderFooter(ContactPage)} />
				<Route exact path="/kompete" component={withHeaderFooter(Kompete)} />
				<Route exact path="/terms" component={withHeaderFooter(Terms)} />
				<Route exact path="/privacy" component={withHeaderFooter(Privacy)} />
				<Route exact path="/copyright" component={withHeaderFooter(CopyRight)} />
			</Switch>
		</BrowserRouter>
	)
}

export default Routes
