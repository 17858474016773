import React, { useRef, useEffect } from 'react'
import Animate from 'components/Animate'
import { Helmet } from 'react-helmet'

const Kompete = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const ref = useRef()

	return (
		<div className="LegacyPage">
			<Helmet>
				<title>Creator of KOMPETE - Modernize Games</title>
				<meta name="description" content="Wondering who created KOMPETE? It was Modernize Games led by Justin Meister." />
			</Helmet>
			<div className="firstScreen">
				<div className="container">
					<Animate animateIn="fadeIn" animateOut="fadeOut" duration={2} delay={300}>
						<img className="logo" src="/images/kompete_first.svg" alt="Kompete" />
						<h1></h1>
					</Animate>
					<div
						className="arrow"
						onClick={(e) => {
							ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
						}}
					>
						<Animate animateIn="slide" className="animate__infinite" initiallyVisible={true} offset={0} duration={1.6}>
							<img src="/images/down_arrow_yellow.svg" alt="down" />
						</Animate>
					</div>
				</div>
			</div>
			<div className="secondScreen" ref={ref}>
				<div className="container">
					<div className="firstBlock">
						<Animate animateIn="fadeInUp" animateOnce={true}>
							<h2 className="headText">The Problem With Gaming</h2>
							<p className="descriptionText">
								A lot of the world's most popular game modes come with high barriers to entry that limit a huge number of gamers from being able to have fun and
								play with their friends.
							</p>
						</Animate>
					</div>
					<div className="secondBlock">
						<Animate animateIn="fadeInUp" animateOnce={true}>
							<h2 className="headText">The Solution</h2>
							<img src="/images/kompete.svg" alt="KOMPETE" />
						</Animate>
					</div>
					<div className="thirdBlock">
						<Animate animateIn="fadeInUp" animateOnce={true}>
							<h2 className="headText">What is KOMPETE?</h2>
							<p className="descriptionText">
								KOMPETE is a team multiplayer all-in-one sport game that’s free-to-play with cross-platform play. Players create a highly customizable avatar, a
								KOMPETITOR and take their KOMPETITOR to play in the universe’s most popular sports!
							</p>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={200}>
							<div className="flex">
								<div className="first">
									<img src="/images/kompete_page_1.jpg" className="big" alt="kompete image" />
									<div>
										<img src="/images/kompete_page_2.jpg" className="small" alt="kompete image" />
										<img src="/images/kompete_page_3.jpg" className="small" alt="kompete image" />
									</div>
								</div>
								<div className="second">
									<img src="/images/kompete_page_6.jpg" className="big" alt="kompete image" />
									<div>
										<img src="/images/kompete_page_4.jpg" className="small" alt="kompete image" />
										<img src="/images/kompete_page_5.jpg" className="small" alt="kompete image" />
									</div>
								</div>
							</div>
						</Animate>
					</div>
				</div>
			</div>
			<div className="thirdScreen">
				<div className="container">
					<Animate animateIn="fadeInUp" animateOnce={true}>
						<h2 className="headText">How To Play</h2>
						<p className="descriptionText">It’s easy to get started with three simple steps:</p>
					</Animate>
					<div className="flex">
						<Animate animateIn="fadeIn" animateOnce={true} offset={0}>
							<div className="item">
								<div className="imageContainer">
									<p>1</p>
									<img src="/images/play_1.png" />
								</div>
								<h2>Create Your KOMPETITOR</h2>
								<p>Our character customizer lets players morph anyone that they want.</p>
							</div>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={400} offset={0}>
							<div className="item">
								<div className="imageContainer">
									<p>2</p>
									<img src="/images/play_2.jpg" />
								</div>
								<h2>Select Your Attributes</h2>
								<p>Select how your KOMPETITOR will play in each sport by allocating attributes.</p>
							</div>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={800} offset={0}>
							<div className="item">
								<div className="imageContainer">
									<p>3</p>
									<img src="/images/play_3.jpg" />
								</div>
								<h2>KOMPETE in Sports</h2>
								<p>Team up with your friends on any device and play in all your favorite sports.</p>
							</div>
						</Animate>
					</div>
				</div>
			</div>
			<div className="fourthScreen">
				<div className="container">
					<Animate animateIn="fadeInUp" animateOnce={true}>
						<h2 className="headText">Four Sports At Launch</h2>
					</Animate>
					<div className="flex first">
						<Animate animateIn="fadeIn" animateOnce={true} offset={0}>
							<div className="item">
								<img src="/images/sports/basketball.svg" alt="basketball" />
								<p>Basketball</p>
							</div>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={400} offset={0}>
							<div className="item">
								<img src="/images/sports/battle.svg" alt="Battle Royale" />
								<p>Battle Royale</p>
							</div>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={800} offset={0}>
							<div className="item">
								<img src="/images/sports/kart_race.svg" alt="Kart Race" />
								<p>Kart Race</p>
							</div>
						</Animate>
						<Animate animateIn="fadeIn" animateOnce={true} delay={1200} offset={0}>
							<div className="item">
								<img src="/images/sports/golf_ball.svg" alt="Golf" />
								<p>Golf</p>
							</div>
						</Animate>
					</div>
					<Animate animateIn="fadeInUp" animateOnce={true}>
						<h2 className="headText">Future Sports</h2>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={200}>
						<div className="flex second">
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							<div className="item">
								<img src="/images/sports/unknown.svg" alt="unknown" />
								<p>To Be Announced</p>
							</div>
							{/* <div className='item'>
                <img src='/images/sports/hockey.svg' alt='Hockey' />
                <p>Hockey</p>
              </div>
              <div className='item'>
                <img src='/images/sports/rugby.svg' alt='American football' />
                <p>American football</p>
              </div>
              <div className='item'>
                <img src='/images/sports/baseball.svg' alt='Baseball' />
                <p>Baseball</p>
              </div>
              <div className='item'>
                <img src='/images/sports/footman.svg' alt='Mini game royale' />
                <p>Mini game royale</p>
              </div>
              <div className='item'>
                <img src='/images/sports/stadium.svg' alt='Kart football' />
                <p>Kart football</p>
              </div>
              <div className='item'>
                <img src='/images/sports/user_groups.svg' alt='Social deceit' />
                <p>Social deceit</p>
              </div> */}
						</div>
					</Animate>
				</div>
			</div>
			<div className="fifthScreen">
				<Animate animateIn="fadeInUp" animateOnce={true}>
					<h2 className="headText">Why KOMPETE</h2>
				</Animate>
				<div className="bg">
					<Animate animateIn="fadeIn" animateOnce={true} delay={200} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Free-to-play sports game</span>
						</h3>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={400} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Never pay-to-win</span>
						</h3>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={600} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Available on all devices</span>
						</h3>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={800} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Cross-platform play</span>
						</h3>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={1000} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Quality multiplayer gameplay</span>
						</h3>
					</Animate>
					<Animate animateIn="fadeIn" animateOnce={true} delay={1200} offset={0}>
						<h3>
							<img src="/images/checked.svg" alt="" />
							<span>Game asset ownership</span>
						</h3>
					</Animate>
				</div>
			</div>
			<div className="sixthScreen">
				<h2 className="headText">Anyone. Anywhere. Anytime.</h2>
				<p className="descriptionText">Access all your favorite sports games under one roof.</p>
			</div>
			<div className="seventhScreen">
				<div className="container">
					<div>
						<h2 className="headText">Ready to KOMPETE?</h2>
						<p className="descriptionText">Check out the official KOMPETE website today!</p>
						<a href="https://kompete.game/" className="yellowBtn withoutArrow" target="_blank">
							Visit Now
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Kompete
