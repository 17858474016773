import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import parse from 'html-react-parser'

const CopyRight = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const privacy = gql`
		query {
			privacyTerms(where: { slag: "copyright" }) {
				content {
					html
				}
			}
		}
	`

	const GetCopyRight = () => {
		const { loading, error, data } = useQuery(privacy)

		if (loading) return
		if (error) return

		if (data) {
			return parse(data.privacyTerms.content.html)
		}
	}

	return (
		<div className="DocumentPage">
			<div className="inner">
				<p className="heading">Modernize Games - Copyright</p>
				<div className="content">{GetCopyRight()}</div>
			</div>
		</div>
	)
}
export default CopyRight
