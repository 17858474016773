import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import parse from 'html-react-parser'

const terms = gql`
	query {
		privacyTerms(where: { slag: "terms" }) {
			content {
				html
			}
		}
	}
`
const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const GetTerms = () => {
		const { loading, error, data } = useQuery(terms)

		if (loading) return
		if (error) return

		if (data) {
			return parse(data.privacyTerms.content.html)
		}
	}

	return (
		<div className="DocumentTermPage">
			<div className="inner">
				<h2 className="heading">TERMS OF SERVICE</h2>
				<div className="content">
					<p className="underTitle">
						Welcome to Modernize Games! Please read these Terms of Service and our Privacy Policy carefully before using our Services.
					</p>
					<p>
						By using our Services, whether as a guest, as a registered user, or otherwise, you agree that these Terms of Service will govern your relationship
						with Modernize Marketing LLC. If you do not completely agree to these Terms of Service then you must not use any of our Services. We may amend these
						Terms at any time by posting a revised version on the Website. Each revised version will state its effective date, which will be effective on or
						after the date in which it is posted or sent. Your continued use of the Services and Website after the effective date constitutes your acceptance of
						the revised Terms.
					</p>
					<div className="items">
						<ol>
							<li>
								<h5>Definitions</h5>
								<ul>
									<li>
										<strong>“Dispute”</strong> means, any controversy related to these Terms, including without limitation claims arising out of or relating to
										any aspect of the relationship between you and Company, claims that arose before these Terms, and claims that may arise after the
										termination of these Terms; however, this does not include claims related to or arising from the enforcement or protection of Intellectual
										Property Rights, and is subject to any applicable statutory consumer rights laws in your local jurisdiction.
									</li>
									<li>
										<strong>“Company”</strong> means, Modernize Marketing LLC. References to “Us,” “We,” or “Our” means Modernize Marketing LLC, including any
										and all subsidiaries parent companies, joint ventures, and other corporate entities under common ownership and/or any of their agents,
										consultants, employees, officers, and directors. Company does not include Company Affiliates or third parties (analytics or ad tech
										companies, or similar organizations).
									</li>
									<li>
										<strong>“Company Affiliate” (or “Company Affiliates”)</strong> means, Company’s third-party content providers, distributors, licensees, or
										licensors.
									</li>
									<li>
										<strong>“Game”</strong> means the online team multiplayer sport game known as KOMPETE. Please refer to the Game’s Terms of Servic for terms
										and conditions of the Game.
									</li>
									<li>
										<strong>“Intellectual Property Rights”</strong> means, any and all right, title, and interest of every kind whatsoever, whether now known or
										unknown, registrable or otherwise, in and to patents, trade secret rights, copyrights, trademarks, service marks, trade dress and similar
										rights of any type under the laws of any governmental authority, including, without limitation, all applications and registrations relating
										to the foregoing.
									</li>
									<li>
										<strong>“Notice”</strong> means, a delivered writing by e-mail or courier delivery to the other party at their respective address and will
										be effective upon receipt.
									</li>
									<li>
										<strong>“Privacy Policy”</strong> means, Company’s policy regarding privacy, which also governs your use of the Services and is incorporated
										herein by reference. The current version is available on our website.
									</li>
									<li>
										<strong>“Service” (or “Services”)</strong> means, the Game and any website provided by Company and Company Affiliates, including without
										limitation the following websites and all sub-pages thereof:{' '}
										<a rel="noopener noreferrer" href="https://www.modernizegames.com" target="_blank">
											www.modernizegames.com
										</a>{' '}
										(the “Website”).
									</li>
									<li>
										<strong>“Terms of Service”</strong> (or “Terms”) means these terms of service and end user license agreement and any terms, conditions,
										covenants, representations, warranties, obligations, and licenses contained herein.
									</li>
								</ul>
							</li>
							<li>
								<h5>Ownership and Limited License</h5>
								<ol className="list-alpha">
									<li>
										<strong>Ownership.</strong> The Services are owned or licensed by Company and are protected by Intellectual Property Rights and other
										proprietary rights laws. Company reserves all right, title, and interest in and to the Services, including without limitation all
										Intellectual Property Rights and other proprietary rights, that are not explicitly granted to you in these Terms. Your permitted use of the
										Services is limited by the Intellectual Property Rights of Company.
									</li>
									<li>
										<strong>License.</strong> Subject to your agreement and continuing compliance with these Terms of Service and any other relevant Company
										policies, Company grants you a non-commercial, non-exclusive, non-transferable, revocable, limited license, subject to the limitations in
										these Terms, to access and use the Services for your own entertainment and informational purposes. You agree that you will not use the
										Services for any other purpose.
									</li>
									<li>
										<strong>License Limitations.</strong> Any use of the Services in violation of the law, these Terms of Service, or these License Limitations
										is strictly prohibited, and may result in the immediate revocation of your limited license at Company’s sole judgment or may subject you to
										liability for violations of law.
										<br />
										<br />
										You acknowledge you will not directly or indirectly:
										<ol className="list-roman">
											<li>Partake in any activity or action that Company deems to be against the spirit or intent of the Services;</li>
											<li>
												Copy, modify, edit, create derivative works of, publicly display, publicly perform, republish, transmit, or distribute the Services or
												any other material obtained through the Services;
											</li>
											<li>
												Lease, sell, rent, or otherwise exploit for commercial purposes any part of the Services, including without limitation access to or use
												of the Services;
											</li>
											<li>
												Delete, alter, or obscure any Intellectual Property Rights or other proprietary rights notices from copies of materials from the
												Services;
											</li>
											<li>
												Attempt to harass, threaten, bully, embarrass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person,
												group, Company itself or Company Affiliates;
											</li>
											<li>
												Organize or participate, in conjunction with your use of the Services, in any activity or group that is hateful, harmful, or offensive
												towards a race, sexual orientation or preferences, religion, heritage or nationality, disability or other health class, gender, age, or
												similar classes determined by Company;
											</li>
											<li>
												Organize or participate, in conjunction with your use of the Services, in any activity or group that is hateful, harmful, or offensive
												towards a race, sexual orientation or preferences, religion, heritage or nationality, disability or other health class, gender, age, or
												similar classes determined by Company;
											</li>
											<li>
												Use robots, spiders, crawlers, man-in-the-middle software, or any other automated process to access, use, reverse engineer, or
												manipulate the Services, or Company;
											</li>
											<li>
												Use of access services to obtain, generate, or infer any business information about Company or Company Affiliates, including without
												limitation information about sales or revenue, staff, technical stack, or statistics about users;
											</li>
											<li>
												Promote, encourage, or participate in any activity involving hacking, phishing, distribution of counterfeit Services, or taking
												advantage of or creating exploits, cheats, bugs, or errors, except for the sole purpose of privately and directly notifying Company;
											</li>
											<li>
												Make available through the Services any material or information that infringes any intellectual property right, right of privacy, right
												of publicity, or other right of any person or entity or impersonates any other person, including without limitation celebrities and
												Company employees;
											</li>
											<li>Attempt to gain unauthorized access to Services; and</li>
											<li>Use the Services where it is prohibited by law.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5>Term</h5>
								<p>
									Unless modified or amended by Company, these Terms and its provisions shall remain in full force and effect. Termination of any license
									granted by Company under these Terms does not affect any other provisions of these Terms.
								</p>
							</li>
							<li>
								<h5>Access and Permissible Assignment</h5>
								<ol className="list-alpha">
									<li>
										By using the Services, you warrant and represent that you: 1) are at least 18 years of age and otherwise legally competent to read,
										understand and accept the provisions of these Terms; or 2) are a minor who has been authorized under the provisions of Section 4(b) below.
									</li>
									<li>
										If you are the legal guardian of a minor, you can choose to allow use of the Services by that minor subject to the following provisions:
										<ol className="list-roman">
											<li>
												If you are the legal guardian of a minor, you can choose to allow use of the Services by that minor subject to the following provisions:
											</li>
											<li>You acknowledge, and further agree you are entirely responsible for all the provisions in these Terms of Service;</li>
											<li>
												You acknowledge, and further agree, you are legally responsible for all actions of that minor, including but not limited to any
												payments, damages and/or liabilities related to the actions of that minor;
											</li>
											<li>
												In consideration of Company allowing access to the Service by a minor, and in addition to the provisions of Section 8 below, the
												foregoing adult hereby guarantees and agrees to pay for any and all liabilities of any nature whatsoever incurred under these Terms and
												to defend, indemnify and hold harmless Company with respect thereto.
											</li>
										</ol>
									</li>
									<li>If you have been previously banned from using any Company Services then you may not use our Services.</li>
									<li>
										Notwithstanding the above provisions of Section 4, if you are located in a country that requires parental consent for Services to collect or
										use your data at a higher age than 13 (e.g., certain countries following the General Data Protection Regulation) AND you are under that
										country’s specified age, you must not use any part of the Services or submit any personal information to Company through the Services or
										otherwise unless a) consent was given or authorized by the holder of parental responsibility of that child and b) you receive a Notice that
										consent was confirmed from Company.
									</li>
								</ol>
							</li>
							<li>
								<h5>Service Availability and Termination</h5>
								<ol className="list-alpha">
									<li>
										You acknowledge that:
										<ol className="list-roman">
											<li>
												Company may in its sole and absolute discretion provide subsequent amendments, versions, enhancements, modifications, upgrades or
												patches related to any part of the Services;
											</li>
											<li>
												Company has absolute and sole discretion to immediately terminate or restrict access to the Services, or any portion of the Services at
												any time, for any reason, without Notice and without liability to you;
											</li>
											<li>
												Company has absolute and sole discretion to immediately terminate or restrict access to the Services, or any portion of the Services at
												any time, for any reason, without Notice and without liability to you;
											</li>
											<li>Company may not offer the Services in all countries or geographic locations;</li>
											<li>You are solely responsible for any internet connection and/or mobile fees that you may incur as a result of using our Services.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5>Intellectual Property</h5>
								<ol className="list-alpha">
									<li>
										All content included as part of the Services, including but not limited to, text, graphics, logos, images, as well as the compilation
										thereof, the look and feel of the Services, including, without limitation, the text, graphics, code, and other materials contained hereon is
										property of the Company or its third party suppliers, or is rightfully used subject to a license agreement and is protected by copyright,
										trademark, and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright,
										trademark, and other proprietary notices, legends, or other restrictions contained in any such content and will not make any changes
										thereto.
									</li>
									<li>
										You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works of, or in any way
										exploit any of the content, in whole or in part, provided on, transmitted through, or found within the Services. Your use of the Services
										does not entitle you to make any unauthorized use of any such content, and in particular you shall not delete or alter any proprietary
										rights or attribution notices in such content. You shall use the content provided through, transmitted through, and found on the Services
										solely for your personal use, as outlined in these Terms, and will make no other use of the Content without the express written permission
										of the Company and/or related third party owners of such content. You acknowledge and agree that you do not acquire any ownership rights in
										the content provided through the Services. Except as provided herein, these Terms do not grant any licenses, express or implied, to such
										content or any other intellectual property of the Company, its licensors, or any third party owner.
									</li>
								</ol>
							</li>
							<li>
								<h5>Links to Third Party Sites and Services</h5>
								<p>
									The Services may contain links to third party websites (“<strong>Linked Sites</strong>”). The Linked Sites are not under the control of
									Company or any part of the Services. Company is not responsible for the contents of any Linked Site, including without limitation any link
									contained in a Linked Site, or any changes or updates to a Linked Site. Company is providing these links to you only as a convenience, and the
									inclusion of any link does not imply endorsement by Company of the website or any association with its operators.
								</p>
							</li>
							<li>
								<h5>Payment and Purchases</h5>
								<p>
									The Company may offer the Services on Linked Sites, or other third party websites and platforms with no direct affiliation with Company. All
									payments and purchases on Linked Sites or third party websites and platforms, such as Steam, shall be subject to the individual Linked Site’s,
									website’s or platform’s payment terms and conditions. Company does not control payment or purchases by You on Linked Sites, third party
									websites and platforms and shall not be responsible for management or refund of any such payment.
								</p>
							</li>
							<li>
								<h5>Prohibited Conduct</h5>
								<p>
									You are granted a non-exclusive, non-transferable, revocable license to access and use the Services, strictly in accordance with these Terms.
									As a condition of your use of the Services, you represent and warrant to Company that you will not use the Services for any purpose that is
									unlawful or prohibited by these Terms. Further, you agree that you will comply with these Terms and will not:
								</p>
								<ol className="list-alpha">
									<li>
										Use the Services in any manner which could damage, disable, overburden, or impair the Website, Game, or Game or Website servers, or
										otherwise interfere with any other party's use and enjoyment of the Services;
									</li>
									<li>
										Obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the
										Services;
									</li>
									<li>
										Impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the Services accounts of others without
										permission, forge another person’s digital signature, misrepresent the source, identity, or content of information transmitted via the
										Services, or perform any other similar fraudulent activity;
									</li>
									<li>
										Defame, harass, abuse, threaten or defraud users of the Services, or collect, or attempt to collect, personal information about users or
										third parties without their consent; and
									</li>
									<li>
										Attempt to indicate in any manner that you have a relationship with Company or that Company has endorsed you or any products or services for
										any purpose.
									</li>
								</ol>
								<p>
									Company also reserves all available legal rights and remedies to prevent the unauthorized use of the Services, including, but not limited to,
									technological barriers, IP mapping, and contacting your internet service provider.
								</p>
							</li>
							<li>
								<h5>Disclaimer, Warranty, and Liability</h5>
								<ul>
									<li>YOU ACKNOWLEDGE THAT COMPANY AND COMPANY AFFILIATES ARE NOT LIABLE</li>
									<li>
										(1) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL OR DATA, IN ANY WAY
										WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICES; OR
									</li>
									<li>(2) FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICE AND OPERATORS OF EXTERNAL SITES.</li>
									<li>
										THE SERVICES ARE PROVIDED BY COMPANY TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY
										KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICES, UNLESS SUCH WARRANTIES OR OTHER STATUTORY CONSUMER RIGHTS ARE LEGALLY
										INCAPABLE OF EXCLUSION OR LIMITATION IN YOUR LOCAL JURISDICTION. THE RISK OF USING THE SERVICES RESTS ENTIRELY WITH YOU AS DOES THE RISK OF
										INJURY FROM THE SERVICES. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT
										LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT WARRANT THAT THE SERVICES ARE FREE
										FROM VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULLEST EXTENT ALLOWED BY ANY LAW THAT APPLIES, THE DISCLAIMERS OF LIABILITY IN THESE TERMS
										APPLY TO ALL DAMAGES OR INJURY CAUSED BY THE SERVICES, OR RELATED TO USE OF, OR INABILITY TO USE, THE SERVICES, UNDER ANY CAUSE OF ACTION IN
										ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE).
									</li>
									<li>
										TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE AGGREGATE LIABILITY OF COMPANY AND/OR COMPANY AFFILIATES ARISING OUT OF OR IN
										CONNECTION WITH THESE TERMS SHALL NOT EXCEED THE TOTAL AMOUNTS YOU HAVE PAID (IF ANY) TO COMPANY AND/OR COMPANY AFFILIATES IN THE ONE
										HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.
									</li>
									<li>
										IF YOU HAVE NOT PAID COMPANY OR ANY COMPANY AFFILIATE ANY AMOUNT IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE
										DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH COMPANY AND/OR ANY COMPANY AFFILIATE IS
										TO CEASE USING THE SERVICES.
									</li>
									<li>
										YOU REPRESENT AND WARRANT THAT YOU ARE NOT LOCATED IN A COUNTRY THAT IS SUBJECT TO A UNITED STATES GOVERNMENT EMBARGO, OR THAT HAS BEEN
										DESIGNATED BY THE UNITED STATES GOVERNMENT AS A “TERRORIST SUPPORTING” COUNTRY, AND YOU ARE NOT LISTED ON ANY UNITED STATES GOVERNMENT LIST
										OF PROHIBITED OR RESTRICTED PARTIES.
									</li>
									<li>
										SOME STATES, COUNTRIES, OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, OR THE EXCLUSION OR LIMITATION OF LIABILITY FOR
										CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH STATES, COUNTRIES, OR JURISDICTIONS, COMPANY AND COMPANY AFFILIATES SHALL BE LIMITED TO THE
										MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, SUBJECT TO ANY APPLICABLE STATUTORY CONSUMER RIGHTS LAWS IN YOUR LOCAL JURISDICTION.
									</li>
								</ul>
							</li>
							<li>
								<h5>Indemnity</h5>
								<ol className="list-alpha">
									<li>
										You agree to defend, indemnify and hold harmless Company, Company Affiliates, and any third-parties under agreement with Company, and any
										employee, contractor, vendor, agent, supplier, licensee, customer, distributor, shareholder, director or officer of any of the foregoing, as
										well as any person using the Services and any person or entity that becomes aware of your use of the Services at any time, with respect to
										any and all third-party claims, liabilities, judgments, awards, injuries, damages, losses, costs, fees, or expenses (including but not
										limited to attorney’s fees and costs) that arise under, from or in any way, directly or indirectly, relate to:
										<ol className="list-roman">
											<li>Your failure to comply with any provision of these Terms of Service;</li>
											<li>
												Your use of the Services, including but not limited to economic, physical, emotional, psychological or privacy related considerations;
												and
											</li>
											<li>
												Your use of the Services, including but not limited to economic, physical, emotional, psychological or privacy related considerations;
												and
											</li>
										</ol>
									</li>
									<li>
										Company and Company Affiliates reserve the right, but not the obligation, at their own expense, to assume the exclusive defense and control
										of any matter otherwise subject to indemnification by you.
									</li>
									<li>
										You acknowledge, and further agree that Company has no obligation to defend, indemnify or hold harmless you in any way related to these
										Terms, including but not limited to your use of the Services, use of the Services by any person, or any connection between the foregoing and
										any other person or entity that becomes aware of your use of the Services at any time.
									</li>
									<li>This Section shall survive the termination of these Terms.</li>
								</ol>
							</li>
							<li>
								<h5>Dispute Resolution</h5>
								<ol className="list-alpha">
									<li>
										<strong>Informal Resolution.</strong> With respect to any Dispute, you agree to attempt to negotiate the resolution of any Dispute
										informally for at least thirty (30) days before initiating any arbitration or other proceeding, including any legal proceeding in court or
										before an administrative agency. Such informal negotiations commence upon Company’s receipt of Notice from you.
									</li>
									<li>
										<strong>Mandatory Binding Arbitration.</strong> If you are not able to satisfactorily resolve a Dispute informally within a total of thirty
										(30) days, or if Company, in its sole and absolute discretion, determines that it will not be possible to satisfactorily resolve that
										Dispute informally within a total of thirty (30) days, you agree that either you or Company may request resolution by final and fully
										binding arbitration conducted under the Commercial Arbitration Rules of the American Arbitration Association. The party requesting that a
										Dispute be resolved by arbitration under this Section shall be responsible for initiating such a proceeding.
										<ol className="list-roman">
											<li>
												The American Arbitration Association (“AAA”) will run the arbitration between you and Company, and AAA’s rules and procedures (including
												their Supplementary Procedures for Consumer-Related Disputes, if applicable) will be used. If something in these Terms is different than
												AAA’s rules and procedures, then we will follow these Terms instead. You can look at AAA’s rules and procedures on their website
												www.adr.org or you can call them at 1-800-778-7879.
											</li>
											<li>
												YOU UNDERSTAND, AND FURTHER AGREE, THAT YOU HAVE THE RIGHT TO CONSULT WITH INDEPENDENT LEGAL COUNSEL OF YOUR OWN CHOOSING REGARDING THIS
												AND ANY OTHER PROVISION IN THESE TERMS AND THAT THIS BINDING ARBITRATION PROVISION WILL ELIMINATE YOUR LEGAL RIGHT TO SUE IN COURT, TO
												HAVE A JURY TRIAL, AND/OR TO PARTICIPATE IN A CLASS ACTION WITH RESPECT TO ANY SUCH DISPUTE. You and Company both agree that neither
												shall attempt to have any other arbitration or class action related to any other party joined to any arbitration in which you are
												involved with Company. To the fullest extent permitted by law, no arbitration proceeding shall be decided on a class-action basis or
												utilizing class action procedures. You and Company further agree that each may bring claims against the other only in an individual
												capacity, and not as a plaintiff or a class member in any purported class or representative proceeding.
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5>Miscellaneous</h5>
								<ol className="list-alpha">
									<li>
										<strong>Reps and Warranties.</strong> In addition to any other representations and warranties made herein, you hereby represent and warrant
										that i) you are not prohibited from receiving or using any aspect of the Services under applicable laws and ii) Company has not previously
										disabled your Account or your access to the Services for a violation of the law or these Terms or other applicable agreements, terms of use,
										or contracts.
									</li>
									<li>
										<strong>Electronic Communication.</strong> Each and every time you send an email or other electronic communication to Company, such
										communication will constitute an electronic communication. By using the Services, you consent to receive electronic communications and you
										agree that all agreements, Notices, disclosures and other communications that Company provides to you via electronic communication,
										individually and collectively, satisfy any legal requirement that such communications be in writing.
									</li>
									<li>
										<strong>Release.</strong> You hereby agree to release Company from all damages (whether direct, indirect, incidental, consequential or
										otherwise), losses, liabilities, costs and expenses of every kind and nature, known and unknown, arising out of a dispute between you and a
										third-party (including other Users) in connection with the Services and any content therein. In furtherance of the foregoing, you hereby
										waive any applicable law or statute, which says, in substance: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES
										NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
										SETTLEMENT WITH THE RELEASED PARTY."
									</li>
									<li>
										<strong>Changes.</strong> It is your responsibility to read, understand, and accept these Terms in connection with your use of the Services.
										You acknowledge that Company may make changes to these Terms of Service at any time, and that Section headings in these Terms are for
										purposes of convenience only. Unless Company states otherwise, any changes to these Terms are effective when posted. If you continue to use
										the Services after any changes are posted then you agree that those changes will apply to your continued use of the Services and that such
										use constitutes your acceptance of the same. You should check this page regularly to stay informed about any changes.
									</li>
									<li>
										<strong>Complete agreement.</strong> These Terms: (1) is the final and complete agreement and understanding of the parties concerning the
										subject matter hereof, and supersedes and replaces any and all prior and contemporaneous agreements and understandings with respect thereto;
										(2) may not be changed, amended, or in any manner modified by you except as authorized in a writing signed by both parties’ authorized
										agents; (3) is not assignable, except to a successor in interest to substantially all of a party’s business or assets and any other attempt
										to assign or transfer these Terms or any interest herein is void; and (4) shall be binding upon, and inure to the benefit of, the parties
										hereto, their respective heirs, executors, administrators, successors, personal representatives, licensees, and assigns.
									</li>
									<li>
										<strong>Force Majeure.</strong> No party shall be responsible for delays or failure of performance resulting from acts beyond the reasonable
										control of such party, including, war, terrorism, acts of public enemies, strikes or other labor disturbances, power failures, fires,
										floods, earthquakes, acts of God, and other natural disasters.
									</li>
									<li>
										<strong>Waiver.</strong> No act or failure to act by Company will be deemed a waiver of any right contained in these Terms, and any waiver
										by Company must be in writing and signed by an officer of Company. If Company does expressly waive any provision of these Terms, such waiver
										shall not be a waiver of any other provisions of these Terms, and the waived provision shall not be waived for all time in the future.
									</li>
									<li>
										<strong>Severability.</strong> If any provision or sub-provision of these Terms is found to be invalid or unenforceable, the remainder shall
										be enforced as fully as possible and the unenforceable provision or sub-provision shall be deemed modified to the limited extent required to
										permit its enforcement in a manner most closely representing the intention of the parties as expressed herein.
									</li>
									<li>
										<strong>Remedies.</strong> You acknowledge and agree that any violation or threatened violation of these Terms will cause irreparable injury
										to Company, entitling Company to seek injunctive relief without the necessity of proving actual damages, in addition to all other remedies
										at law or in equity. You specifically acknowledge that money damages alone would be an inadequate remedy for the injuries and damages that
										would be suffered and incurred by Company as a result of a breach of any of the provisions of these Terms.
									</li>
									<li>
										<strong>Governing Law and Venue.</strong> Any dispute or claim arising out of or related to these Terms shall be governed by and construed
										in accordance with the laws of the State of California without reference to any choice or conflict of laws principles. Accordingly, the
										parties hereby consent to the personal jurisdiction of the courts in San Diego County, California and hereby waive any and all
										jurisdictional or venue defenses otherwise available to them.
									</li>
									<li>
										<strong>Language.</strong> To the fullest extent permitted by law, the controlling language for these Terms of Service is English.
									</li>
								</ol>
							</li>
							<li>
								<h5>Apple Enabled Applications</h5>
								<ul>
									<li>
										The Services operate in connection with products made commercially available by Apple, Inc. (“Apple”), in addition to other devices and
										operating systems. With respect to Services that are made available to you in connection with an Apple-branded product (such Services shall
										hereinafter be known as “Apple-Enabled Services”), the following terms and conditions apply, in addition to the terms and conditions set
										forth in these Terms:
									</li>
									<li>
										You and Company acknowledge that these Terms operate between you and Company only and not with Apple and that as between Company and Apple,
										Company, not Apple, is responsible for the Apple-Enabled Services and the content thereof;
									</li>
									<li>
										You acknowledge that the Apple iOS App Store ("App Store") contains rules and conditions that govern the use of software made available
										therein (collectively "Usage Rules"). Such Usage Rules are contained within the App Store Terms of Service ("App Store Terms"). Regarding
										the Services, you may not use the Apple-Enabled Services in any manner that is in violation of or inconsistent with these Usage Rules or the
										App Store Terms;
									</li>
									<li>
										Your license to use the Apple-Enabled Services is limited to a non-transferable license to use the Apple-Enabled Services on an iOS product
										that you own or control, as permitted by the Usage Rules set forth in the App Store Terms;
									</li>
									<li>
										You acknowledge that Apple has no obligation whatsoever to provide any maintenance or support services with respect to the Apple-Enabled
										Services;
									</li>
									<li>
										You acknowledge that Apple is not responsible for any product or software warranties, whether express or implied by law. In the event of any
										failure of Apple-Enabled Services to conform to any applicable warranty, you may notify Apple, via the methods described in the App Store,
										and Apple will refund the purchase price for the Apple Enabled Services to you, if any; and, to the maximum extent permitted by applicable
										law, Apple will have no other warranty obligation whatsoever with respect to the Apple-Enabled Services, or any other claims, losses,
										liabilities, damages, costs, or expenses attributable to any failure to conform to any warranty, to the extent that it cannot be disclaimed
										under applicable law. For a discussion of any warranties related to the Services, please see the section herein entitled "Disclaimer and
										Limitation of Liability;"
									</li>
									<li>
										Company and you acknowledge that Company, not Apple, is responsible for addressing any claims of you or any third party relating to the
										Apple-Enabled Services or your possession and/or use of those Apple-Enabled Services, including but not limited to i) product liability
										claims; ii) any claim that the Apple-Enabled Services fail to conform to any applicable legal or regulatory requirement; and iii) claims
										arising under consumer protection or similar legislation;
									</li>
									<li>
										In the event of any third party claim that the Apple-Enabled Services or the end-user's possession and use of those Apple-Enabled Services
										infringes that third party's intellectual property rights, as between Company and Apple, Company, not Apple, will be solely responsible for
										the investigation, defense, settlement, and discharge of any such intellectual property infringement claim;
									</li>
									<li>
										You represent and warrant that i) you are not located in any country that is subject to a U.S. government embargo, or that has been
										designated by the U.S. Government as a "terrorist supporting" country; ii) you are not listed on any U.S. Government list of prohibited or
										restricted parties; and iii) you are not located in any other country or jurisdiction from which you would be barred from using the Services
										by applicable law; and
									</li>
									<li>
										If you have any complaints, questions, comments, or concerns with respect to the Apple-Enabled Services, you should direct them to:{' '}
										<a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a>
									</li>
								</ul>
							</li>
							<li>
								<h5>Contact Us</h5>
								<p>
									If you have any questions or comments about these Terms or our Services, please contact us at{' '}
									<a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a>
								</p>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Terms
