import React, { useEffect } from 'react'
import { Map, Marker } from 'pigeon-maps'
import { Helmet } from 'react-helmet'

const ContactPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="ContactPage wrapper">
			<Helmet>
				<title>Connect - Modernize Games</title>
			</Helmet>
			<div className="container">
				<div className="flex">
					<div className="left">
						<h1>Contact Us</h1>
						<h2 className="headText">Something On Your Mind?</h2>
						<p className="descriptionText">
							We’d love to hear from you! <br /> You can get in touch with us at anytime at <a href="mailto:talk@modernizegames.com">talk@modernizegames.com</a>{' '}
							Please use the above email for all press and business.
						</p>
						<div className="address">
							<h2 className="headText">Connect With Us On Socials!</h2>
							<div className="social">
								<a href="https://www.youtube.com/channel/UCbr2u89d9Y6QJh62Ti4t6Ew" target="_blank">
									<img src="/images/social/youtube_black_y.svg" alt="youtube" />
								</a>
								<a href="https://www.twitch.tv/modernizegames" target="_blank">
									<img src="/images/social/twitch_black_y.svg" alt="twitch" />
								</a>
								<a href="https://www.instagram.com/modernizegames/" target="_blank">
									<img src="/images/social/instagram_black_y.svg" alt="instagram" />
								</a>
								<a href="https://www.facebook.com/Modernize-Games-111089404834487" target="_blank">
									<img src="/images/social/facebook_black_y.svg" alt="facebook" />
								</a>
								<a href="https://www.twitter.com/ModernizeGames" target="_blank">
									<img src="/images/social/twitter_black_y.svg" alt="twitter" />
								</a>
							</div>
						</div>
					</div>
					<div className="mapContainer">
						<Map height={445} defaultCenter={[32.714809759099886, -117.16523422066486]} defaultZoom={13}></Map>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactPage
