import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
// import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'
import OgImage from '../../images/og_image.jpg'

// ReactGA.initialize('G-CVT8R7T8PC')
// ReactGA.pageview(window.location.pathname + window.location.search)

const options = {
	autoConfig: true,
	debug: false,
}
ReactPixel.init('635422604327479', {}, options)
ReactPixel.pageView()

const Header = () => {
	const [menuOpened, setMenuOpened] = useState(false)
	return (
		<div className={`header ${menuOpened ? 'active' : ''}`}>
			<Helmet>
				<html lang="en" />
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<title>Best Video Game Studio - Modernize Games</title>
				<meta
					name="description"
					content="Modernize Games is a video game studio in La Jolla, California. The studio is the creator of the free-to-play sports game, KOMPETE."
				/>
				<meta name="author" content="" />
				<meta property="og:title" content="Best Video Game Studio - Modernize Games" />
				<meta
					property="og:description"
					content="Modernize Games is a video game studio in La Jolla, California. The studio is the creator of the free-to-play sports game, KOMPETE."
				/>
				<meta property="og:image" content={OgImage} />
				<meta property="og:url" content="https://modernizegames.com" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@ModernizeGames" />
				<meta name="twitter:title" content="Best Video Game Studio - Modernize Games" />
				<meta
					name="twitter:description"
					content="Modernize Games is a video game studio in La Jolla, California. The studio is the creator of the free-to-play sports game, KOMPETE."
				/>
				<meta name="twitter:image" content={OgImage} />
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://modernizegames.com" />
			</Helmet>
			<div className="container flex">
				<Link to="/" className="logoWrapper">
					<img className="logo" src="/images/logo.svg" alt="Modernize Games" />
				</Link>
				<div className="menu">
					<Link to="/">Home</Link>
					<a href="https://kompete.game">KOMPETE</a>
					<Link to="/contact">Contact</Link>
				</div>
				<div className="social">
					<div className="first">Follow Us</div>
					<div className="second">
						<a href="https://www.twitter.com/ModernizeGames" target="_blank">
							<img src="/images/social/twitter.svg" alt="twitter" />
						</a>
						<a href="https://www.facebook.com/Modernize-Games-111089404834487" target="_blank">
							<img src="/images/social/facebook.svg" alt="facebook" />
						</a>
						<a href="https://www.instagram.com/modernizegames/" target="_blank">
							<img src="/images/social/instagram.svg" alt="instagram" />
						</a>
						<a href="https://www.twitch.tv/modernizegames" target="_blank">
							<img src="/images/social/twitch.svg" alt="twitch" />
						</a>
						<a href="https://www.youtube.com/channel/UCbr2u89d9Y6QJh62Ti4t6Ew" target="_blank">
							<img src="/images/social/youtube.svg" alt="youtube" />
						</a>
					</div>
				</div>
			</div>
			<div className="mobileHeader">
				<Link to="/">
					<img className="logo" src="/images/logo.svg" alt="Modernize Games" />
				</Link>
				<div className={`burger ${menuOpened ? 'active' : ''}`} onClick={() => setMenuOpened(!menuOpened)}>
					<span></span>
				</div>
				{menuOpened ? (
					<Fragment>
						<div className="mobileMenu">
							<Link to="/">Home</Link>
							<a href="https://kompete.game">Kompete</a>
							<Link to="/contact">Contact</Link>
						</div>
						<div>
							<p>Follow Us</p>
							<div className="social">
								<a href="https://www.twitter.com/ModernizeGames" target="_blank">
									<img src="/images/social/twitter.svg" alt="twitter" />
								</a>
								<a href="https://www.facebook.com/Modernize-Games-111089404834487" target="_blank">
									<img src="/images/social/facebook.svg" alt="facebook" />
								</a>
								<a href="https://www.instagram.com/modernizegames/" target="_blank">
									<img src="/images/social/instagram.svg" alt="instagram" />
								</a>
								<a href="https://www.twitch.tv/modernizegames" target="_blank">
									<img src="/images/social/twitch.svg" alt="twitch" />
								</a>
								<a href="https://www.youtube.com/channel/UCbr2u89d9Y6QJh62Ti4t6Ew" target="_blank">
									<img src="/images/social/youtube.svg" alt="youtube" />
								</a>
							</div>
						</div>
					</Fragment>
				) : null}
			</div>
		</div>
	)
}

export default Header
