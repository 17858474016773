import React, { useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'
import Animate from 'components/Animate'
import { Helmet } from 'react-helmet'

const TEAM = gql`
	query {
		teamMembers(skip: 0) {
			teamMemberName
			teamMemberDescription
			backdropTitle
			backdropText
			teamMemberPhoto {
				url(transformation: { image: { resize: { width: 382, height: 284, fit: crop } } })
			}
		}
	}
`

const Team = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const { loading, error, data } = useQuery(TEAM)

	function GetTeam() {
		if (loading) return
		if (error) return

		if (data)
			return (
				<div className="team">
					{data.teamMembers.slice(1).map((member, index) => (
						<div className="teamMemberCard" key={index}>
							<div className="imageContainer" style={{ backgroundImage: `url(${member.teamMemberPhoto ? member.teamMemberPhoto.url : ''})` }}>
								<div className="description">
									<h5>{member.backdropTitle}</h5>
									<p>{member.backdropText}</p>
								</div>
							</div>
							<h3>{member.teamMemberName}</h3>
							<p>{member.teamMemberDescription}</p>
						</div>
					))}
				</div>
			)
	}

	function GetCeo() {
		if (loading) return
		if (error) return

		const { teamMemberPhoto, teamMemberName, teamMemberDescription, backdropText } = data.teamMembers[0]

		return (
			<div className="ceo">
				<img src="/images/Justin.png" alt="ceo" />
				<div>
					<h1>{teamMemberName}</h1>
					<h2>{teamMemberDescription}</h2>
					<p>{backdropText}</p>
				</div>
			</div>
		)
	}

	return (
		<div className="TeamPage">
			<Helmet>
				<title>Meet the Team - Modernize Games</title>
				<meta
					name="description"
					content="Modernize Games has put together one of the best video game development teams in the history of multiplayer gaming."
				/>
			</Helmet>
			<Animate animateIn="fadeIn" animateOnce={true}>
				<div className="container">
					<div className="head">
						<h2 className="headText">The Modernize Games Team</h2>
						<p className="descriptionText">Meet our experienced and talented team.</p>
						{GetCeo()}
					</div>
					{GetTeam()}
				</div>
			</Animate>
		</div>
	)
}

export default Team
