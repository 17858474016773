import React from 'react'
import ReactDOM from 'react-dom'
import './css/app.min.css'
import 'animate.css/animate.min.css'
import Routes from './Routes'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'

export const client = new ApolloClient({
	uri: 'https://api-us-east-1.graphcms.com/v2/ckv2idlqi2f0901yzfjeuaqao/master',
	cache: new InMemoryCache(),
})

ReactDOM.render(
	<ApolloProvider client={client}>
		<Routes />
	</ApolloProvider>,
	document.getElementById('root')
)
