import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
	return (
		<div className="footer">
			<div className="head">
				<div className="container flex">
					<p>Join the conversation</p>
					<div className="social">
						<a href="https://www.youtube.com/channel/UCbr2u89d9Y6QJh62Ti4t6Ew" target="_blank">
							<img src="/images/social/youtube_yellow_b.svg" alt="youtube" />
						</a>
						<a href="https://www.twitch.tv/modernizegames" target="_blank">
							<img src="/images/social/twitch_yellow_b.svg" alt="twitch" />
						</a>
						<a href="https://www.instagram.com/modernizegames/" target="_blank">
							<img src="/images/social/instagram_yellow_b.svg" alt="instagram" />
						</a>
						<a href="https://www.facebook.com/Modernize-Games-111089404834487" target="_blank">
							<img src="/images/social/facebook_yellow_b.svg" alt="facebook" />
						</a>
						<a href="https://www.twitter.com/ModernizeGames" target="_blank">
							<img src="/images/social/twitter_yellow_b.svg" alt="twitter" />
						</a>
					</div>
					<p>Join the conversation</p>
				</div>
			</div>
			<div className="bottom">
				<div className="container flex">
					<Link to="/">
						<img className="item" src="/images/logo.svg" alt="Modernize Games" />
					</Link>
					<div className="menu">
						<div className="item">
							<Link to="/">Home</Link>
						</div>
						<div className="item">
							<a href="https://kompete.game">Kompete</a>
						</div>
						<div className="item">
							<Link to="/contact">Contact Us</Link>
						</div>
					</div>
					<div className="info">
						<Link to="/terms">Terms & Conditions</Link>
						<Link to="/privacy">Privacy Policy</Link>
					</div>
				</div>
				<p>© {new Date().getFullYear()} Modernize Games. All Rights Reserved.</p>
			</div>
		</div>
	)
}

export default Footer
