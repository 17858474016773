import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import parse from 'html-react-parser'

const privacy = gql`
	query {
		privacyTerms(where: { slag: "privacy" }) {
			content {
				html
			}
		}
	}
`
const GetPrivacy = () => {
	const { loading, error, data } = useQuery(privacy)

	if (loading) return
	if (error) return

	if (data) {
		return parse(data.privacyTerms.content.html)
	}
}

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="DocumentTermPage">
			<div className="inner">
				<h2 className="heading">PRIVACY POLICY</h2>
				<div className="content">
					<p className="underTitle">
						<strong>Welcome to Modernize Games! </strong>
						This Privacy Policy will explain what information we collect online, why we collect it, and the choices we offer to you.
					</p>
					<p>
						This privacy policy (“Policy”) describes the data practices of Modernize Marketing LLC (“Company” “we” or “us”), covering the online and other
						services under its control, including the website at www.modernizegames.com (the “Website”) and any other website or application (collectively
						"Services"). By using the Services, you are consenting to this Policy and the processing of your data in the manner provided herein. If you do not
						agree with this Policy, please do not use the Services, provided us with any personal information, or purchase any of our products. This Privacy
						Policy was last updated on <strong>July 11, 2022.</strong>
					</p>
					<div className="items">
						<ul>
							<li>
								<h5>Information We Collect</h5>
								<ul>
									<li>
										We may collect information about the Services you use and how you use them, such as data regarding your usage of the Website, how you found
										the Website, and activity on the Website. We collect PII, DII, and log information about your interactions as described below.
									</li>
									<li>
										We may collect information about the Services you use and how you use them, such as data regarding your usage of the Website, how you found
										the Website, and activity on the Website. We collect PII, DII, and log information about your interactions as described below.
									</li>
									<li>
										The Services and use thereof may also create or collect device-identifiable information (DII), such as cookies, unique device and
										advertising identifiers, statistical identifiers, usernames, and similar identifiers that are linkable to a browser or device. From these
										platforms, we may also receive other information, such as your IP address, user agent, timestamps, precise and imprecise geolocation, sensor
										data, apps, fonts, battery life information, and screen size.
									</li>
									<li>
										Our Services also collect information about your interactions, including navigation paths, search queries, crashes, timestamps, purchases,
										clicks and shares, and referral URLs. We may combine this data with PII and DII. For efficiency’s sake, information about your interactions
										may be transmitted to our servers while you are not using the Services. We may also partner with third-parties that collect additional
										information (i.e. Steam or Apple) – please note that we do not control the data processing or information collection practices of these
										parties. For more information on how Steam uses or otherwise processes your personal data, please see the{' '}
										<a href=" https://store.steampowered.com/privacy_agreement/" rel="noopener noreferrer" target="_blank">
											Steam privacy policy{' '}
										</a>{' '}
										and{' '}
										<a href="https://www.apple.com/legal/privacy/en-ww/" rel="noopener noreferrer" target="_blank">
											Apple privacy policy
										</a>
										.
									</li>
									<li>
										With respect to reviews or any other content posted by you to the Website, you acknowledge and agree that such forums are public. Any
										information posted in those areas is viewable and usable by anyone that has access to the Services.
									</li>
								</ul>
							</li>
							<li>
								<h5>Categories of PII We Collect</h5>
								<p>We may have collected the following information from consumers within the past 12 months:</p>
								<table>
									<thead>
										<tr>
											<th>Category Number</th>
											<th>Category</th>
											<th>Examples</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1.</td>
											<td>Identifiers</td>
											<td>Name, email address, IP address, online identifier, social media accounts, device identifiers, username, or other similar data</td>
										</tr>
									</tbody>
								</table>
							</li>
							<li>
								<h5>How We Use Information We Collect</h5>
								<ul>
									<li>
										We use the information we collect from our Services to provide, maintain, protect and improve our Services, to develop new Services and
										offerings, and to protect us and our users.
									</li>
									<li>
										We use the information we collect from our Services to provide, maintain, protect and improve our Services, to develop new Services and
										offerings, and to protect us and our users.
										<ol className="list-alpha-upper left-padding-true">
											<li>To meet the reason for which the PII was provided.</li>
											<li>To provide you with information, products, or services you request and/or purchase from us.</li>
											<li>To provide you with information, products, or services you request and/or purchase from us.</li>
											<li>To improve our Website and performance of the contents therein.</li>
											<li>To resolve disputes.</li>
											<li>Preventing potentially fraudulent, prohibited, or illegal activities.</li>
											<li>Enforcing the Terms of Service.</li>
											<li>
												For other purposes as permitted by applicable law and not inconsistent with this Privacy Policy or any other express statement we make
												at the point of collection of the PII.
											</li>
										</ol>
									</li>
									<li>
										<h5>How We Share the Information we Collect</h5>
										<ul>
											<li>
												We may share some or all of the above listed categories of PII and DII with companies, outside organizations, and individuals for
												limited reasons and the following business purposes, outlined below:
												<ol className="list-alpha-upper left-padding-true">
													<li>We may share PII and other data with companies, outside organizations or individuals if we have your consent to do so.</li>
													<li>
														For external processing - We may provide PII to our affiliates, service providers, or other trusted businesses or persons to process
														it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and
														security measures.
													</li>
													<li>
														For legal reasons - We will share PII with companies, outside organizations or individuals if we have a good-faith belief that
														access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal
														process or enforceable governmental request, detect, prevent, or otherwise address fraud, security or technical issues or protect
														against harm to the rights, property or safety of our users or the public as required or permitted by law.
													</li>
													<li>
														In case of a sale or asset transfer - If we become involved in a merger, acquisition or other transaction involving the sale of some
														or all of our assets, user information, including PII collected from you through your use of our Services, could be included in the
														transferred assets. Should such an event occur, we will use reasonable means to notify you, either through email and/or a prominent
														notice on the Services.
													</li>
													<li>
														In aggregated form for business purposes - We may share aggregated information and DII with our partners such as businesses we have
														a relationship with, advertisers or connected sites. For example, we may share information to show trends about the general use of
														our Services.
													</li>
												</ol>
											</li>
											<li>
												In aggregated form for business purposes - We may share aggregated information and DII with our partners such as businesses we have a
												relationship with, advertisers or connected sites. For example, we may share information to show trends about the general use of our
												Services.
											</li>
											<li>
												In the past 12 months, we may have shared the following categories of PII:
												<ul className="list-disc left-padding-true">
													<li style={{ marginTop: '5px' }}>Identifiers</li>
												</ul>
											</li>
											<li>We do not otherwise share or supply PII or DII to third parties. We do not sell or rent your PII to marketers or third parties.</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>
								<h5>Third-Parties</h5>
								<p>
									While we strive to work with reputable companies with good privacy practices, this Policy does not apply to services offered by other
									companies or individuals, including products or sites that may be displayed to you on the Services. We also do not control the privacy
									policies and your privacy settings on third-party sites, including social networks. For further information, please see the relevant privacy
									policies for each third-party and industry codes of conduct.
								</p>
							</li>
							<li>
								<h5>Choice</h5>
								<ul>
									<li>
										Like many other companies, we do not honor DNT flags but instead offer other choices with respect to third-parties. Many third-parties
										participate in self-regulation to offer you a choice regarding receiving targeted ads. Please note that you’ll still see generic ads after
										opting out, but they won’t be based on your activities online. On the web, you can opt out of participating companies by visiting the
										following sites:
										<ul className="list-disc left-padding-true">
											<li>
												<a href="http://optout.networkadvertising.org/" rel="noopener noreferrer" target="_blank">
													http://optout.networkadvertising.org/
												</a>
											</li>
											<li>
												<a href="http://optout.aboutads.info/" rel="noopener noreferrer" target="_blank">
													http://optout.aboutads.info/
												</a>
											</li>
											<li>
												<a href="http://optout.aboutads.info/" rel="noopener noreferrer" target="_blank">
													http://optout.aboutads.info/
												</a>
											</li>
											<li>
												<a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">
													https://tools.google.com/dlpage/gaoptout
												</a>
											</li>
											<li>
												<a href="https://support.apple.com/en-us/HT202074 " rel="noopener noreferrer" target="_blank">
													https://support.apple.com/en-us/HT202074{' '}
												</a>
											</li>
										</ul>
									</li>
									<li>
										If you wish to similarly opt out of cross-app advertising on mobile devices, you can enable the Limit Ad Tracking flag on the device.
										Enabling Limit Ad Tracking sends a flag to third-parties that you wish to opt out of targeted advertising on that device, and major mobile
										platforms require companies to honor this flag. Screenshots on how to find these options on various devices are available here:
										<a href="http://www.networkadvertising.org/mobile-choices " rel="noopener noreferrer" target="_blank">
											http://www.networkadvertising.org/mobile-choices{' '}
										</a>
										. To learn how to opt out on other devices, please visit the platform’s privacy policies for more information.
									</li>
								</ul>
							</li>
							<li>
								<h5>Information Security</h5>
								<p>
									We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold
									and undertake reasonable security measures with appropriate confidentiality, integrity, and availability protections. However, since no
									software or storage system is 100% secure, we cannot and do not guarantee the security of your information associated with the Services, or
									any other service for that matter. You can help protect your account information by using unique and hard-to-guess passwords.
								</p>
							</li>
							<li>
								<h5>Data Retention </h5>
								<p>
									PII or DII that we process for any purpose, as outlined herein, will not be kept for longer than necessary to accomplish that purpose or
									purposes. Notwithstanding the foregoing, we may retain your PII or DII where such retention is necessary for compliance with a legal
									obligation to which we are subject, or to protect your vital interests or the vital interests of another natural person.
								</p>
							</li>
							<li>
								<h5>Children Under 13</h5>
								<ul>
									<li>
										We do not knowingly collect information for any child under the age of 13. If you are the parent of a child under the age of 13 and have a
										concern regarding your child’s information on our Services, please contact us at the email provided at the end of this Policy. If we
										discover that we have been sent the personal information of a child under 13, we will take steps to delete that information as soon as
										possible.
									</li>
									<li>
										If Company ever does in the future collect, use or disclose personal information from your child, we will endeavor to obtain parent of legal
										guardian’s consent before doing so or otherwise comply with the law. If Company does collection information, no more information than is
										reasonably required to participate in the activity shall be required to be disclosed. We will not keep personal information for longer than
										necessary. Parents or legal guardians may also contact us to ask if we have collected their child’s personal information, to review that
										information, request that Company stop collection this information and have it deleted, and refuse to allow further collection or use of the
										child’s information. Such requests are subject to Company verifying to our satisfaction that the requester is in fact the child’s parent or
										legal guardian. A parent or legal guardian may contact Company at <a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a> to
										make requests and review such information.
									</li>
								</ul>
							</li>
							<li>
								<h5>Location of Services </h5>
								<p>
									Our Services are directed to United States citizens. If you are outside of the United States, your information will be processed and stored in
									the United States and you are consenting to such transfer, processing, and storage under the laws of the United States.
								</p>
							</li>
							<li>
								<h5>Changes</h5>
								<p>
									Our Privacy Policy may change from time to time. We will post any Policy changes on this page and within the settings of any mobile apps.
									Please check back periodically to view changes to our privacy policy. Your continued use of the Services following posting of such changes
									constitutes your acceptance of the modified terms.
								</p>
							</li>
							<li>
								<h5>Questions?</h5>
								<p>
									If you have questions or requests regarding our privacy practices, please contact us at{' '}
									<a href="mailto:legal@modernizegames.com ">legal@modernizegames.com </a>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Privacy
