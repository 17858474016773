import React, { useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { TwitterShareButton, FacebookShareButton, TelegramShareButton } from 'react-share'
import { Helmet } from 'react-helmet'

const POST = gql`
	query($slug: String!) {
		post(where: { slug: $slug }) {
			id
			postTitle
			subtitle
			postContent {
				html
			}
			postTag
			readingTime
			dateOfPost
			previewImage {
				url(transformation: { image: { resize: { width: 382, height: 214, fit: crop } } })
			}
		}
	}
`

const PostPage = () => {
	const { id } = useParams()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	function GetPost(id) {
		const { loading, error, data } = useQuery(POST, {
			variables: { slug: id },
		})

		if (loading) return
		if (error) return

		if (data) {
			return (
				<div className="post">
					<Helmet>
						<title>{data.post.postTitle}</title>
						<meta name="description" content={data.post.subtitle} />
						<meta name="author" content="" />
						<meta property="og:title" content={data.post.postTitle} />
						<meta property="og:description" content={data.post.subtitle} />
						<meta property="og:image" content={data.post.previewImage ? data.post.previewImage.url : ''} />
						<meta property="og:url" content={`https://modernizegames.com/post/${data.post.slug}`} />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@ModernizeGames" />
						<meta name="twitter:title" content={data.post.postTitle} />
						<meta name="twitter:description" content={data.post.subtitle} /> />
						<meta name="twitter:image" content={data.post.previewImage ? data.post.previewImage.url : ''} />
						<meta name="robots" content="noodp, noydir" />
					</Helmet>
					<h1>{data.post.postTitle}</h1>
					<p>{data.post.subtitle}</p>
					<div className="flex info">
						<div>
							<span>
								{new Date(data.post.dateOfPost).toLocaleString('en-EN', {
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})}
							</span>
							&nbsp; &bull; &nbsp;
							<span>{`${data.post.readingTime} min read`}</span>
						</div>
						<div>
							<TwitterShareButton url={window.location.href}>
								<img src="/images/social/twitter_black.svg" alt="twitter" />
							</TwitterShareButton>
							<FacebookShareButton url={window.location.href}>
								<img src="/images/social/facebook_black.svg" alt="twitter" />
							</FacebookShareButton>
							<TelegramShareButton url={window.location.href}>
								<img src="/images/social/telegram_black.svg" alt="twitter" />
							</TelegramShareButton>
						</div>
					</div>
					<div className="content">{data.post.postContent ? parse(data.post.postContent.html) : null}</div>
					<div className="flex postFooter">
						{data.post.postTag ? <h3>{data.post.postTag}</h3> : null}
						<div>
							<TwitterShareButton url={window.location.href}>
								<img src="/images/social/twitter_black.svg" alt="twitter" />
							</TwitterShareButton>
							<FacebookShareButton url={window.location.href}>
								<img src="/images/social/facebook_black.svg" alt="twitter" />
							</FacebookShareButton>
							<TelegramShareButton url={window.location.href}>
								<img src="/images/social/telegram_black.svg" alt="twitter" />
							</TelegramShareButton>
						</div>
					</div>
				</div>
			)
		}
	}

	return <div className="PostPage wrapper">{GetPost(id)}</div>
}

export default PostPage
