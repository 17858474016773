import React, { useRef, useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import Animate from 'components/Animate'
import { Helmet } from 'react-helmet'

const TEAM = gql`
	query {
		teamMembers(first: 3) {
			teamMemberName
			teamMemberDescription
			backdropTitle
			backdropText
			teamMemberPhoto {
				url(transformation: { image: { resize: { width: 382, height: 284, fit: crop } } })
			}
		}
	}
`

const POSTS = gql`
	query {
		posts(first: 3, orderBy: dateOfPost_DESC) {
			slug
			postTitle
			subtitle
			previewImage {
				url(transformation: { image: { resize: { width: 382, height: 214, fit: crop } } })
			}
			postContent {
				html
			}
			postTag
			readingTime
			dateOfPost
		}
	}
`

const JOBS = gql`
	query {
		jobPositions {
			kindOfWork
		}
	}
`

const HomePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const ref = useRef()

	function GetTeam() {
		const { loading, error, data } = useQuery(TEAM)

		if (loading) return
		if (error) return

		if (data)
			return (
				<div className="team">
					{data.teamMembers.map((member, index) => (
						<div className="teamMemberCard" key={index}>
							<Animate animateIn="fadeIn" delay={index * 400} animateOnce={true} offset={0}>
								<div className="imageContainer" style={{ backgroundImage: `url(${member.teamMemberPhoto ? member.teamMemberPhoto.url : ''})` }}>
									<div className="description">
										<h5>{member.backdropTitle}</h5>
										<p>{member.backdropText}</p>
									</div>
								</div>
							</Animate>
							<Animate animateIn="fadeInUp" delay={index * 400} animateOnce={true}>
								<h3>{member.teamMemberName}</h3>
								<p>{member.teamMemberDescription}</p>
							</Animate>
						</div>
					))}
				</div>
			)
	}

	function getCeoImg() {
		const { loading, error, data } = useQuery(TEAM)

		if (loading) return
		if (error) return

		return <img alt="ceo" className="right" src={data.teamMembers[0].teamMemberPhoto.url} />
	}

	const GetPosts = () => {
		const { loading, error, data } = useQuery(POSTS)

		if (data) console.log(data)

		if (loading) return
		if (error) return

		if (data)
			return (
				<div className="posts flex">
					{data.posts.map((post, index) => (
						<Link to={`/post/${post.slug}`} key={index}>
							<Animate animateIn="fadeIn" delay={index * 400} animateOnce={true} offset={0}>
								<div className="item">
									<div className="imageContainer">
										<img src={post.previewImage ? post.previewImage.url : ''} alt={post.postTitle} />
									</div>
									<div className="contentWrapper">
										<h1>{post.postTitle}</h1>
										<p>{post.subtitle}</p>
									</div>
									<div className="divider"></div>
									<div className="flex">
										<div>
											<span>
												{new Date(post.dateOfPost).toLocaleString('en-EN', {
													month: 'short',
													day: 'numeric',
													year: 'numeric',
												})}
											</span>
											&nbsp; &bull; &nbsp;
											<span>{`${post.readingTime} min read`}</span>
										</div>
										{post.postTag ? <h3>{post.postTag}</h3> : null}
									</div>
								</div>
							</Animate>
						</Link>
					))}
				</div>
			)
	}

	const getJobImage = (kindOfWork) => {
		switch (kindOfWork) {
			case 'design':
				return '/images/hiring/design.svg'
			case 'development':
				return '/images/hiring/programming.svg'
			case 'marketing':
				return '/images/hiring/goal.svg'
			case 'animations':
				return '/images/hiring/animations.svg'
			case 'art':
				return '/images/hiring/art.svg'
			default:
				return '/images/hiring/physics.svg'
		}
	}

	function GetJobs() {
		const { loading, error, data } = useQuery(JOBS)

		if (loading) return
		if (error) return

		if (data) {
			const grouped = {}
			data.jobPositions.map((item) => {
				grouped.hasOwnProperty(item.kindOfWork.toLowerCase())
					? grouped[item.kindOfWork.toLowerCase()].push(item)
					: (grouped[item.kindOfWork.toLowerCase()] = [item])
			})
			const grouped2 = {}
			let counter = 0
			for (let prop in grouped) {
				if (counter >= 5) break
				counter++
				grouped2[prop] = grouped[prop]
			}
			return (
				<>
					{Object.keys(grouped2).map((group, index) => (
						<Animate animateIn="fadeIn" delay={index * 300 + 300} animateOnce={true} offset={0} key={index}>
							<div className="item">
								<h6>{grouped2[group].length}</h6>
								<div className="backdrop"></div>
								<img src={getJobImage(group)} alt="Design" />
								<p>{group}</p>
							</div>
						</Animate>
					))}
				</>
			)
		}
	}

	return (
		<div className="HomePage">
			<Helmet>
				<title>Modernize Games</title>
			</Helmet>
			<div className="firstScreen">
				<div className="container">
					<Animate animateIn="fadeIn" animateOut="fadeOut" duration={2} delay={300}>
						<h1>We Modernize Games</h1>
					</Animate>
					<div
						className="arrow"
						onClick={(e) => {
							ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
						}}
					>
						<Animate animateIn="slide" className="animate__infinite" initiallyVisible={true} offset={0} duration={1.6}>
							<img src="/images/down_arrow.svg" alt="down" />
						</Animate>
					</div>
				</div>
			</div>
			<div className="secondScreen" ref={ref}>
				<div className="container">
					<Animate animateIn="fadeInUp" animateOnce={true}>
						<h2 className="headText">Our Mission</h2>
						<p className="descriptionText top">
							Create free multiplayer games with no barriers to entry that are accessible on any device, to anyone in the world, at any given time, while
							creating a fun, repeatable, gameplay loop with superb technical performance.
						</p>
					</Animate>
				</div>
				<div className="container flex second">
					<div className="left">
						<Animate animateIn="fadeInUp" animateOnce={true}>
							<h2 className="headText">Origins</h2>
							<p className="descriptionText">
								Justin Meister, also known as Dastro, founded Modernize Games in September of 2020 as a frustrated response to yet another year of being charged
								$70 for a broken NBA2K gameplay experience. Sick and tired of no changes being made, Meister decided to divert to start a game studio and create
								his own game.
							</p>
						</Animate>
					</div>
					<img alt="ceo" className="right" src="/images/Justin.png" />
				</div>
			</div>
			<div className="fourthScreen">
				<div className="container flex">
					<div className="left">
						<Animate animateIn="fadeInUp" animateOnce={true}>
							<h2 className="headText">
								Creators of{' '}
								<span>
									<img src="/images/Frame%2043.svg" alt="Kompete" />
								</span>
							</h2>
							<p className="descriptionText">
								KOMPETE is an all-in-one multiplayer game. Current game modes include Blitz Royale, Kart Race, & Social Deduction, with more coming soon! Now in
								Early Access.
							</p>
						</Animate>
						<Animate animateIn="fadeIn" delay={400} animateOnce={true}>
							<Link to="/kompete" className="yellowBtn">
								Learn More
							</Link>
						</Animate>
					</div>
					<div className="right">
						<div>
							<img src="/images/kompete_1.png" className="big" alt="kompete image" />
							<div>
								<img src="/images/kompete_2.png" className="small" alt="kompete image" style={{ marginTop: '-3px' }} />
								<img src="/images/kompete_3.png" className="small" alt="kompete image" style={{ marginTop: '-3px' }} />
							</div>
						</div>
						<div>
							<div>
								<img src="/images/kompete_4.png" className="small" alt="kompete image" />
								<img src="/images/kompete_5.png" className="small" alt="kompete image" />
							</div>
							<img src="/images/kompete_6.png" className="big" alt="kompete image" />
						</div>
					</div>
				</div>
			</div>
			<div className="eighthScreen">
				<div className="container">
					<div>
						<h2 className="headText">Want to Talk?</h2>
						<p className="descriptionText">
							Press? Business? Partnership? If you want to <br /> talk, please reach out!
						</p>
						<Link to="/contact" className="yellowBtn withoutArrow">
							Connect
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomePage
