import React, { useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'
import Animate from 'components/Animate'
import { Helmet } from 'react-helmet'

const JOBS = gql`
	query {
		jobPositions {
			positionName
			kindOfWork
			workLocation
			schedule
			description
		}
	}
`

const HiringPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	function GetJobs() {
		const { loading, error, data } = useQuery(JOBS)

		if (loading) return
		if (error) return

		if (data) {
			const grouped = {}
			data.jobPositions.map((item) => {
				grouped.hasOwnProperty(item.kindOfWork.toLowerCase())
					? grouped[item.kindOfWork.toLowerCase()].push(item)
					: (grouped[item.kindOfWork.toLowerCase()] = [item])
			})
			return (
				<div>
					{Object.keys(grouped).map((group) => (
						<div className="group">
							<h3>{group}</h3>
							{grouped[group].map((job, index) => (
								<div className="job flex" key={index}>
									<div className="left">
										<h2>{job.positionName}</h2>
										<p>
											<span>{job.workLocation}</span>
											<span>{job.schedule}</span>
										</p>
										<p>{job.description}</p>
									</div>
									<a href="mailto:talk@modernizegames.com">Inquire</a>
								</div>
							))}
						</div>
					))}
				</div>
			)
		}
	}

	return (
		<div className="HiringPage">
			<Helmet>
				<title>Best Video Game Development Jobs - Modernize Games</title>
				<meta name="Find the best video game development jobs at Modernize Games. Our studio is a wonderful place to work!" />
			</Helmet>
			<Animate animateIn="fadeIn" animateOnce={true}>
				<div className="container">
					<h2 className="headText">Available Positions</h2>
					<p className="descriptionText">Think you’d be a great fit at Modernize Games? We’re always looking for creative, talented, and dedicated people.</p>
					<div className="block">{GetJobs()}</div>
				</div>
			</Animate>
		</div>
	)
}

export default HiringPage
