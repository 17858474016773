import React, { useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import Animate from 'components/Animate'
import { Helmet } from 'react-helmet'

const POSTS = gql`
	query {
		posts(orderBy: dateOfPost_DESC) {
			slug
			postTitle
			subtitle
			previewImage {
				url(transformation: { image: { resize: { width: 382, height: 214, fit: crop } } })
			}
			postContent {
				html
			}
			postTag
			readingTime
			dateOfPost
		}
	}
`

const News = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const GetPosts = () => {
		const { loading, error, data } = useQuery(POSTS)

		if (data) console.log(data)

		if (loading) return
		if (error) return

		if (data)
			return (
				<div className="posts flex">
					{data.posts.map((post, index) => (
						<Link to={`/post/${post.slug}`} key={index}>
							<div className="item">
								<div className="imageContainer">
									<img src={post.previewImage ? post.previewImage.url : ''} alt={post.postTitle} />
								</div>
								<div className="contentWrapper">
									<h1>{post.postTitle}</h1>
									<p>{post.subtitle}</p>
								</div>
								<div className="divider"></div>
								<div className="flex">
									<div>
										<span>
											{new Date(post.dateOfPost).toLocaleString('en-EN', {
												month: 'short',
												day: 'numeric',
												year: 'numeric',
											})}
										</span>
										{post.readingTime ? (
											<>
												&nbsp; &bull; &nbsp;
												<span>{`${post.readingTime} min read`}</span>
											</>
										) : null}
									</div>
									{post.postTag ? <h3>{post.postTag}</h3> : null}
								</div>
							</div>
						</Link>
					))}
				</div>
			)
	}

	return (
		<div className="NewsPage">
			<Helmet>
				<title>News - Modernize Games</title>
				<meta name="Stay up to date with the latest news from Modernize Games." />
			</Helmet>
			<div className="container">
				<Animate animateIn="fadeIn" animateOnce={true}>
					<h2 className="headText">In the news</h2>
					<p className="descriptionText">Here’s the latest on Modernize Games.</p>
					{GetPosts()}
				</Animate>
			</div>
		</div>
	)
}

export default News
